import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import "./index.scss";


type Props = {
  componentToRenderInEmptySpace?: ReactElement;
  isDisplayingBackButton?: boolean;
};

const ContainerBox: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
  function renderComponentInEmptySpace() {
    return props.componentToRenderInEmptySpace
      ? props.componentToRenderInEmptySpace
      : null;
  }

  return (
    <div className={"ContainerBox"}>
      <div
        style={{ height: 50 }}
        className={props.isDisplayingBackButton ? "back-button" : ""}
      >
        {renderComponentInEmptySpace()}
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
};

export default ContainerBox;
